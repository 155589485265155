import {createContext, useContext, useEffect, useState} from "react";
import { db } from "../Services/database.service";
import {data_createOrUpdateReponsePourEvaluation} from "../Services/data.service";
import {useSynchronisationContext} from "./synchronisation.provider";

const OnlineStateQueryableContext = createContext();

const OnlinestatequeryableProvider = ({children}) => {

	const [ isOnline, setIsOnline ] = useState(navigator.onLine);
	const [ synchronizing, setSynchronizing ] = useState(false);
	const { setSyncTasks, addReponseItem } = useSynchronisationContext();

	const synchronize = () => {
		if ( ! synchronizing ){
			console.log("combien de fois synchronize est appelé.");
			setSynchronizing(true);
			db.reponse.filter( reponse_item => reponse_item.dirty ).toArray()
				.then(
					reponsesToSync => {
						const nReponsesToSync = reponsesToSync.length;
						if ( nReponsesToSync > 0){
							reponsesToSync.forEach( reponseItem => {
								addReponseItem(reponseItem);
							});
						}
						return new Promise( (resolve, reject) => {
							setSynchronizing(false);
							resolve(`Nombre de réponses à sync : ${nReponsesToSync}`);
						})
					}
				)
				.then( message => {
					setSynchronizing(false);
					console.log(message);
					setSyncTasks(true);
				})
		}

	};

	const listenToOnlineEvent = (event) => {
		if ( ! isOnline ){
			setIsOnline(true);
			synchronize();
		}
	};

	const listenToOfflineEvent = (event) => {
		setIsOnline(false);
	};

	useEffect( () => {
		window.removeEventListener('online', listenToOnlineEvent);
		window.removeEventListener('offline', listenToOfflineEvent);
		window.addEventListener('online', listenToOnlineEvent);
		window.addEventListener('offline', listenToOfflineEvent);
	});

	return (
		<OnlineStateQueryableContext.Provider value={{isOnline}}>
			{children}
		</OnlineStateQueryableContext.Provider>
	)
};

const useOnlineState = () => {
	return useContext(OnlineStateQueryableContext);
}

export { useOnlineState, OnlinestatequeryableProvider };
