import Dexie from "dexie";

const database_name = "scrprod.app.questionnaire.db";

const db = new Dexie(database_name);

// un agenda est une suite de questionnaire dont les évaluations sont
// ordonnées par dates de visite.
// id : int, questionnaires: object, version: string

// ! TODO normaliser les données pour que la base aie sa propre carac

// le flag dirty d'une réponse indique si elle a été synchronisée
if (db){
	db.version(4).stores({
		agenda: '++id, version, questionnaires',
		reponse: '++id, question_id, evaluation_id, valeur, repondu_le, dirty',
		evaluations: '++id, questionnaire_id, evaluations',
		evaluation: '++id, evaluation_id, evaluation',
		questionnaires: '++id, questionnaires',
		evenements: '++id, evenements',
		evenement: '++id, evenement_id, evenement',
	});
}

const update_agenda = (db, data) => {
	return db.agenda.toCollection().first().then( (agenda_item) => {
		if ( ! agenda_item ){
			return db.agenda.add({evaluations: data.data.evaluations, version: 'la version'});
			// return db.agenda.add({questionnaires: data.data.questionnaires, version: 'la version'});
		}else{
			// return db.agenda.put({id: agenda_item.id, questionnaires: data.data.questionnaires, version: 'la version'})
			return db.agenda.put({id: agenda_item.id, evaluations: data.data.evaluations, version: 'la version'})
		}
	})
}

const update_questionnaires = (db, data) => {
	return db.questionnaires.toCollection().first().then( (questionnaires_item) => {
		if ( ! questionnaires_item ){
			return db.questionnaires.add({questionnaires: data.data});
		}else{
			return db.questionnaires.put({id: questionnaires_item.id, questionnaires: data.data})
		}
	})
}

const update_evenements = (db, data) => {
	return db.evenements.toCollection().first().then( ( evenements_item ) => {
		if ( ! evenements_item ){
			return db.evenements.add({evenements: data.data});
		}else{
			return db.evenements.put({id: evenements_item.id, evenements: data.data});
		}
	})
}

const update_evaluations = (db, questionnaire_id, data) => {
	return db.evaluations.where({questionnaire_id: questionnaire_id}).first()
		.then( (evaluations_item) => {
		if ( ! evaluations_item ){
			return db.evaluations.add({questionnaire_id: questionnaire_id, evaluations: data.data});
		}else{
			return db.evaluations.put({id: evaluations_item.id, questionnaire_id: questionnaire_id, evaluations: data.data})
		}
	})
}

const update_evaluation = (db, evaluation_id, data ) => {
	return db.evaluation.where({evaluation_id: evaluation_id}).first()
		.then( (evaluation_item) => {
			if ( ! evaluation_item ){
				return db.evaluation.add({evaluation_id: evaluation_id, evaluation: data.data});
			}else{
				return db.evaluation.put({id: evaluation_item.id, evaluation_id: evaluation_id, evaluation: data.data});
			}
		})
}
const update_evenement = (db, evenement_id, data ) => {
	return db.evenement.where({evenement_id: evenement_id}).first()
		.then( (evenement_item) =>{
			if ( ! evenement_item ){
				return db.evenement.add({evenement_id: evenement_id, evenement: data.data});
			}else{
				return db.evenement.put({id: evenement_item.id, evenement_id: evenement_id, evenement: data.data});
			}
		})
}

const update_reponse = (db, question_id, evaluation_id, valeur, dirty) => {
	return db.reponse.where({evaluation_id: evaluation_id, question_id: question_id}).first()
		.then( reponse_item => {
			// On stocke tout en utc iso 8601 [ATOM]
			const now_utc = (new Date()).toISOString();
			if ( ! reponse_item ){
				return db.reponse.add({question_id: question_id,
				valeur: valeur,
				evaluation_id: evaluation_id,
				repondu_le: now_utc,
				dirty: dirty});
			}else{
				return db.reponse.put({id: reponse_item.id,
					question_id: question_id,
					valeur: valeur,
					evaluation_id: evaluation_id,
					repondu_le: now_utc,
					dirty: dirty
				})
			}
		})
}

const get_all_dirty_reponses = ( db ) => {
	return db.reponse.where({dirty: true});
}

export { db, update_agenda, update_questionnaires, update_evaluation, update_evaluations, update_reponse, update_evenements, update_evenement};



