import './../css/BottomBase.css';

const BottomBase = ({children}) => {
	return(
		<section className="bottom-base bg-dark-gray relative">
			<div className="contain relative font-poppins">
				{children}
			</div>
		</section>
	);
}

export default BottomBase;
