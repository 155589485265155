import axios from "axios";
import { axiosFetch } from './axiosInstance';

// Les loader de react router dom nécessite des promises, on les force.

// échanges relatifs aux questionnaires :

// const user_refresh_auth = () => fetch( 'http://dev.app.questionnaire.scr/api/token/refresh', {
// 	headers: {
// 		"Content-Type": "application/json",
// 	},
// 	body: {
// 		"refresh_token": localStorage.getItem('refresh_token')
// 	},
// 	method: 'POST',
// });

const setupRetry = () => {
	localStorage.setItem('_401_retry','1');
}

const data_getQuestionnaires = () => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get('questionnaires')
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

// on attaque les évaluations pour récupérer les questionnaires associés
// inversion métier, pour le répondant une évaluation est un questionnaire,
// pour le créateur de questionnaire c'est la partie question.

// !TODO peut-être changer le endpoint pour quelque chose de moins abrasif, voir la récupération des évaluations plus loin.

const data_getQuestionnaire = (questionnaireId) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`evaluations/${questionnaireId}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) );
	});
}

// échanges relatives aux évaluations :

const data_getEvaluationsParTypeEvaluable = (type_evaluable_id) => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get(`evaluations/filter/type_evaluable/${type_evaluable_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getEvaluationsParQuestionnaireId = (questionnaire_id) => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get(`evaluations/filter/questionnnaire/${questionnaire_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getEvaluation = (evaluation_id) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`evaluation/${evaluation_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_updateEvaluation = (clef,valeur, evaluation_id) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		let data = {};
		data[clef] = valeur;

		axiosFetch.put(`evaluation/${evaluation_id}`, data)
			.then( data => resolve(data) )
			.catch( error => reject(data) )
	})
}

const data_getAgenda = () => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get("/agenda")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getFullAgenda = () => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get("/eva-full-agenda")
		// axiosFetch.get("/full-agenda")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

const data_getEvenements = () => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get("/evenement")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

const data_getEvenement = (evenement_id) => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`/evenement/${evenement_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

// échanges relatifs aux réponses :
// c'est ici ou la sémantique devient tordue ^^' :
//  on veut répondre à une question, donc créer ou mettre à jour une réponse.
// plutôt que de détecter la présence ou non d'une réponse avec un id pour pousser vers
// le bon endpoint avec la bonne méthode et les bons paramètres
// on envoie sur le serveur la sémantique du répondant et la logique métier codée côté serveur fait le reste.

// !TODO voir à long terme les implications en terme d'évolutions pour changer éventuellement.

const data_createOrUpdateReponsePourEvaluation = ( question_id, valeur, evaluation_id ) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.put(`reponse/${question_id}`, {
			valeur: valeur,
			evaluation_id: evaluation_id,
		})
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_synchronizeDisplayedSection = ( jsSection ) => {
	setupRetry();
	return axiosFetch.put(`synchronizesection`, { data: jsSection } );
}

const data_createUserForApp = ( ) => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get('connect')
			.then((response) => resolve(response))
			.catch( error => reject(error))
	});
}

class DataService{
}

const dataService = new DataService();

export { dataService,
	data_getQuestionnaires,
	data_getQuestionnaire,
	data_getEvaluationsParTypeEvaluable,
	data_getEvaluation,
	data_getEvaluationsParQuestionnaireId,
	data_createOrUpdateReponsePourEvaluation,
	data_updateEvaluation,
	data_getAgenda,
	data_getFullAgenda,
	data_synchronizeDisplayedSection,
	data_getEvenements,
	data_getEvenement,
	data_createUserForApp
};
