import { useEffect, useState, useContext, createContext } from "react";
import {data_createOrUpdateReponsePourEvaluation} from "../Services/data.service";
import {db, update_reponse} from '../Services/database.service';

const SynchronisationContext = createContext();

const SynchronisationProvider = ({children}) => {

	const [ syncTasks, setSyncTasks ] = useState(false);
	const [ taskProcessing, setTaskProcessing ] = useState(false);
	const [ reponsesToSync, setReponsesToSync ] = useState([]);

	const addReponseItem = (reponseitem) => {
		setReponsesToSync( (rtosync) => {
			return [...rtosync, {
				question_id: reponseitem.question_id,
				valeur: reponseitem.valeur,
				evaluation_id: reponseitem.evaluation_id,
			}]
		});
	}

	useEffect(() => {
		if ( syncTasks ){
			if( reponsesToSync.length > 0){
				if (  ! taskProcessing ){
					setTaskProcessing(true);
					const reponseitem =  reponsesToSync[0];
					data_createOrUpdateReponsePourEvaluation(reponseitem.question_id, reponseitem.valeur, reponseitem.evaluation_id)
						.then( ( updateData ) => {
							console.log("update réseau pour la valeur");
							console.log(updateData);
							if (db){
								return update_reponse(db,reponseitem.question_id, reponseitem.evaluation_id, reponseitem.valeur, ! navigator.onLine);
							}else{
								return new Promise( (resolve, reject) => resolve('nodb'));
							}
						})
						.then( (reponseitemId) => {
							console.log("update database pour l'item : " + reponseitemId);
							setTaskProcessing(false);
							const rest = reponsesToSync.slice(1);
							setReponsesToSync( rest );
						})
						.catch( (error) => {
							setTaskProcessing(false);
							console.log('Maj non effectuée.');
					})
				}
			}else{
				setSyncTasks(false);
			}
		}
	}, [reponsesToSync, syncTasks]);

	return (
		<SynchronisationContext.Provider value={{setSyncTasks, reponsesToSync, addReponseItem}}>
			{children}
		</SynchronisationContext.Provider>
	);
}

const useSynchronisationContext = () => {
	return useContext(SynchronisationContext);
}

export { SynchronisationProvider, useSynchronisationContext };
