import {createBrowserRouter, defer} from "react-router-dom";
import PageGuarde from "./PageGuarde";
import Presentation from "../Components/presentation.component";
import Login from "../Components/login.component";
import Logout from "../Components/logout.component";
import Questionnaires from "../Components/Metier/Questionnaire/questionnaires.component";
import {
	charge_agenda,
	charge_evaluation,
	charge_evaluations,
	charge_questionnaires,
	charge_evenements,
	charge_evenement
} from "../Services/chargeurs.service";
import Evaluation from "../Components/Metier/Evaluation/evaluation.component";
import Evaluations from "../Components/Metier/Evaluation/evaluations.component";
import Agenda from "../Components/Metier/Agenda/agenda.component";
import {data_getAgenda} from "../Services/data.service";
import FullAgenda from "../Components/Metier/Agenda/fullagenda.component";
import Carte from "../Components/Metier/Agenda/carte.component";
import Evenements from "../Components/Metier/evenements.component";
import Evenement from "../Components/Metier/evenement.component";
import PresentationEvenementComponent from "../Components/presentation.evenement.component";

import * as React from "react";
import FinDeFestivalComponent from "../Components/finfestival.component";

const Router = createBrowserRouter([
	{
		path: "/",
		element: <PageGuarde><FinDeFestivalComponent/></PageGuarde>
	},
	// {
	// 	path: "/",
	// 	element: <PageGuarde><Presentation/></PageGuarde>
	// },
	// {
	// 	path: "/login",
	// 	element: <PageGuarde><Login /></PageGuarde>
	// },
	// {
	// 	path: "/logout",
	// 	element: <PageGuarde><Logout /></PageGuarde>
	// },
	// {
	// 	path: "/questionnaires",
	// 	element: <PageGuarde><Questionnaires /></PageGuarde>,
	// 	loader: async ( {request}) => {
	// 		return defer({data: charge_questionnaires(request)});
	// 	}
	// },
	// {
	// 	path: "/evaluation/:evaluation_id",
	// 	element: <PageGuarde><Evaluation /></PageGuarde>,
	// 	loader: async ( {request,params}) => {
	// 		// const data = data_getEvaluation(params.evaluation_id);
	// 		const id = params.evaluation_id;
	// 		return defer({data: charge_evaluation(id)} );
	// 	}
	// },
	// {
	// 	path: "/evaluations/:questionnaire_id",
	// 	element: <PageGuarde><Evaluations /></PageGuarde>,
	// 	loader: async ( {request,params} ) => {
	// 		const id = params.questionnaire_id;
	// 		//const data = data_getEvaluationsParQuestionnaireId(id);
	// 		return defer({data: charge_evaluations(id)})
	// 	}
	// },
	// {
	// 	path: "/agenda",
	// 	element: <PageGuarde><Agenda /></PageGuarde>,
	// 	loader: async ( {request}) => {
	// 		return defer({data: data_getAgenda()});
	// 	}
	// },
	// {
	// 	// le chargeur est fonction des capacités de la machine,
	// 	// et de l'état en ligne/hors ligne
	// 	path: "/full-agenda",
	// 	element: <PageGuarde><FullAgenda /></PageGuarde>,
	// 	loader: async ( {request} ) => {
	// 		return defer({data: charge_agenda(request)} );
	// 		// return defer( {data: data_getFullAgenda()})
	// 	}
	// },
	// {
	// 	path: "/map/:x/:y",
	// 	element: <PageGuarde><Carte /></PageGuarde>
	// },
	// {
	// 	path: "/evenements",
	// 	element: <PageGuarde><Evenements /></PageGuarde>,
	// 	loader: async ( {request} ) => {
	// 		return defer({data: charge_evenements(request)});
	// 	}
	// },
	// {
	// 	path: "/evenement/:evenement_id",
	// 	element: <PageGuarde><Evenement /></PageGuarde>,
	// 	loader: async ( {request, params} ) => {
	// 		const id = params.evenement_id;
	// 		return defer({data: charge_evenement(id)});
	// 	}
	// }
]);

export default Router;
