import '../css/Base.css';
import { Menu } from '../Components/Elements/menu.component';
// import logo from './../SCRProd_logo.svg';
import logo from './../logo_ChefProLink.png';
import {OnlinestatequeryableProvider} from "../Providers/onlinestatequeryable.provider";
import {OnlineBadge} from "../Components/Monitoring/OnlineBadge.component";
import {Toaster} from "react-hot-toast";
import { OverlayedMenu } from "./OverlayedMenu";
import {useState} from "react";
import {ToggleButtonElement} from "../Components/Elements/toggle.button.element";
import {SynchronisationProvider} from "../Providers/synchronisation.provider";

const Base = ({children}) => {

	const [ menuState, setMenuState ] = useState('menuout');

	const toggleMenuHandler = () => {
		if ( 'menuin' === menuState ){
			setMenuState('menuout');
		}else{
			setMenuState( 'menuin');
		}
	}

	return (
		<SynchronisationProvider>
			<OnlinestatequeryableProvider>
				<main className="font-poppins">
					<div className="background-scr-prod relative">
						<img src={logo} className="logo-scr-prod"/>
						<OnlineBadge />
						<ToggleButtonElement toggleHandler={toggleMenuHandler} toggleState={menuState} />
						<OverlayedMenu menuState={menuState}  toggleHandler={toggleMenuHandler}/>
						{/*<Menu />*/}
						{children}
					</div>
					<Toaster />
				</main>
			</OnlinestatequeryableProvider>
		</SynchronisationProvider>
	);
};

export default Base;
