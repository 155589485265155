import '../css/OverlayedMenu.css';
import { navigation_menu } from '../Config/index';
import { MenuItem } from "../Components/Elements/menuitem.component";
import { useSynchronisationContext } from "../Providers/synchronisation.provider";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { ROOT_URL } from '../Config/index';

const OverlayedMenu = ({menuState, toggleHandler}) => {

	const { reponsesToSync } = useSynchronisationContext();
	const langues = {
		fr: { nativeName: 'Français', svg: ()=>{
			return(
				<svg xmlns="http://www.w3.org/2000/svg" className="flag-icon" viewBox="0 0 640 480">
					<path fill="#fff" d="M0 0h640v480H0z"/>
					<path fill="#002654" d="M0 0h213.3v480H0z"/>
					<path fill="#ce1126" d="M426.7 0H640v480H426.7z"/>
				</svg>
			)} },
		en: { nativeName: 'English', svg: () => {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" className="flag" viewBox="0 0 640 480">
					<path fill="#012169" d="M0 0h640v480H0z"/>
					<path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
					<path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
					<path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
					<path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
				</svg>
			)
			} }
	};
	const { t, i18n } = useTranslation();


	const user_token = localStorage.getItem('user_token');
	const o_user_token = user_token ? jwt_decode(user_token) : null;
	const program_path = o_user_token?.programme ?? '';

	return (
		// <div className="overlayed-menu-container">
		<>
			<nav className={`overlayed-menu ${menuState} navigation-menu flex flex-col gap-2 px-4 py-14`} role="navigation">
				{ navigation_menu.map( (item,index) => {

					if ( 'UI.Menu.Titres.telechargementagenda' === item.title ){
						let reg_exp = new RegExp("/programme/.+\.pdf");
						if ( reg_exp.test(program_path) ){
							return (
								<a href={`${ROOT_URL}${program_path}`} target="__blank" className="navigation-menu__item" key="program">
									<div className="icon-wrapper" >
										{item.icon}
									</div>
									<div className="text-wrapper">
										{t(item.title)}
									</div>
								</a>
							);
						}else{
							return(<></>);
						}

					}
					return (
						<MenuItem key={index} path={item.link} icon={item.icon} title={item.title} toggleHandler={toggleHandler}/>
					);
				})
				}
				<div className="commutateur-lang flex gap-4 self-center">
					{ Object.keys(langues).map( (lng) => {
						return(
							<div className="selecteur-langue" key={lng}>
								<button className={` ${ i18n.resolvedLanguage === lng ? 'border-b-2 border-white' : ''} w-6 pb-2 hover:border-primary hover:border-b-2`}
												style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal'}} type="submit" onClick={ () => { i18n.changeLanguage(lng); } }>
									{langues[lng].svg()}
								</button>
							</div>
						);
					})

					}
				</div>
				<div className="mt-2 hidden">
					Tâches synchronisées : {reponsesToSync.length}
				</div>
			</nav>
		</>
		// </div>
	)
}


export { OverlayedMenu };
