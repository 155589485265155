import * as React from "react";
import * as ReactDOM from "react-dom/client";
// import { createBrowserRouter, defer, redirect, RouterProvider } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import registerServiceWorker from "./Services/sw.init.service";
import Router from './routes/Router';


import "./css/input.css";



import './i18n/i18n';



const root = ReactDOM.createRoot(document.getElementById("root"));

registerServiceWorker();

root.render(
  <React.StrictMode>
    <RouterProvider router={Router} />
  </React.StrictMode>
);
