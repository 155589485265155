import Button from "./Elements/button.component";
import { useTranslation } from "react-i18next";

const FinDeFestivalComponent = () => {

	const { t, i18n } = useTranslation();

	const displayButton = () => {
		if ( localStorage.getItem('user_token') ){
			return ( <Button linkPath="/questionnaires" linkValue={ t('UI.Boutons.continuer')} /> );
		} else{
			return ( <Button linkPath="/login" linkValue={ t('UI.Boutons.seconnecter')} /> );
		}
	}

	return (<>
			<p>Amis gourmands, c&#39;est avec une immense joie et une profonde gratitude que nous avons cl&ocirc;tur&eacute; l&rsquo;application li&eacute;e &agrave; la 3&egrave;me &eacute;dition du Festival de la Gastronomie de Saint-Martin. Une aventure culinaire exceptionnelle qui n&#39;aurait pas &eacute;t&eacute; possible sans l&#39;implication de nombreux acteurs passionn&eacute;s et d&eacute;vou&eacute;s.</p>
			<p>Tout d&#39;abord, un immense MERCI &agrave; l&#39;Office du Tourisme de Saint-Martin pour sa confiance ind&eacute;fectible et son engagement &agrave; faire de cet &eacute;v&eacute;nement une exp&eacute;rience inoubliable pour tous les visiteurs. La pr&eacute;sidente Val&eacute;rie Damaseau et son &eacute;quipe ont travaill&eacute; sans rel&acirc;che pour faire de ce festival une r&eacute;ussite ! </p>
			<p>Nous tenons &eacute;galement &agrave; exprimer notre reconnaissance envers tous les restaurants participants et un grand merci chaleureux &agrave; tous nos supers chefs qui ont partag&eacute; leur passion et leur savoir-faire culinaire, Patrick Jeffroy, Laurent Huguet, Delvenne Arnaud, Anto Cocagne, Lorna Boboua-do Sacramento, Frederic Cyr, Joao Diamante, Teheiura Teahui, Xavier Isabal, Xavier Thuret, Mareya Ibrahim-Jones, Christophe Boyer, Angela Marcenat, Jeremy Aubreton, Mallory Leroux.</p>
			<p>Nous sommes impatients de vous retrouver pour la prochaine &eacute;dition du Festival de la Gastronomie &agrave; Saint-Martin, avec toujours plus de saveurs, de d&eacute;couvertes et de moments magiques.</p>
			<p>L&rsquo;&eacute;quipe SCR Prod</p>
			{/*{displayButton()}*/}
		</>
	);
};

export default FinDeFestivalComponent;
