import {createContext, useContext, useState} from "react";

console.log('Validate.provider.js est recall');

const ValidableContext = createContext();
const ValidableProvider = ({children, section}) => {

	const initStates = () => {
		let toReturn = {};
		for ( const question of section.questions ){
			toReturn[question.id] = { valid: !question.requis }
		}
		return toReturn;
	}
	const [ validationStates, setValidationStates ] = useState(initStates);

	console.log('Validate Provider est redessiné');

	return (
		<ValidableContext.Provider value={{validationStates, setValidationStates}}>
			{children}
		</ValidableContext.Provider>
	);
}

const useValidable = () =>{
	const validableContext = useContext(ValidableContext);
	return validableContext;
}

export { ValidableProvider, useValidable };
