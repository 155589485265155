import Base from "../Layout/Base";
import BottomBase from "../Layout/BottomBase";
import {Header} from "../Layout/Header";

const PageGuarde = ({children}) => {
	return(
		<Base>
			{/*<Header></Header>*/}
			<BottomBase>
				{children}
			</BottomBase>
		</Base>
	);
};

export default PageGuarde;
