import {useOnlineState} from "../../Providers/onlinestatequeryable.provider";
import '../../css/OnlineBadge.css';
import {useTranslation} from "react-i18next";

const OnlineBadge = () => {

	const { isOnline } = useOnlineState();
	const { t } = useTranslation();

	return (
		<div className={`online-badge text-xs absolute py-1 px-4 rounded-md border
		${ isOnline ? 'is-online text-black' : 'is-offline text-white'}`}>
			{ isOnline ? t('UI.Badge.enligne') : t('UI.Badge.horsligne') }
		</div>
	);
}

export { OnlineBadge };
